<template>
<div>
    <van-nav-bar title="出差管理" left-text="返回" @click-left="back" fixed left-arrow style="z-index:1000" />
    <div class="content" v-show="!isSyncData">
        <van-tabs v-model="active" @change="activeChange">
            <van-tab title="出差填报" :name="0" v-if="awayOfficeFil"></van-tab>
            <van-tab title="出差列表" :name="1" v-if="awayOfficeList"></van-tab>
            <van-tab title="出差审核" :name="2" v-if="awayOfficeAudit"></van-tab>
        </van-tabs>
        <div class="edit" v-if="active == 0">
            <van-form class="edit_form" ref="loginForm" label-width="120">
                    <!-- 出差事由 -->
                    <van-field v-model="editForm.reason" label="出差事由" type="textarea" :autosize="{minHeight:50}" required :rules="[{ required: true, message: '请填写出差事由' }]"></van-field>
                    <!-- 请假类型 -->
                    <van-field v-model="editForm.way" label="交通工具" @click="typeShow = true" readonly clickable>
                        <template #input>{{typeList[editForm.way]}}</template>
                    </van-field>
                    <van-popup v-model="typeShow" position="bottom">
                        <van-picker
                        show-toolbar
                        :columns="typeList"
                        @confirm="typeChange"
                        @cancel="typeShow = false;$forceUpdate();"/>
                    </van-popup>
                    <!-- 请假人 -->
                    <van-field v-model="editForm.cityFrom" label="出发城市" @click="ownerIdShow = true,cityType = 1" clickable required :rules="[{ required: true, message: '请选择出发城市' }]">
                        <template #input>
                            <span>{{ editForm.cityFrom }}</span>
                        </template>
                    </van-field>
                    <van-field v-model="editForm.cityTo" label="目的城市" @click="ownerIdShow = true,cityType = 2" clickable required :rules="[{ required: true, message: '请选择出发城市' }]">
                        <template #input>
                            <span>{{ editForm.cityTo }}</span>
                        </template>
                    </van-field>
                    <van-popup v-model="ownerIdShow" position="bottom">
                        <div style="minHeight:300px;">
                            <van-radio-group v-model="userRadio">
                                <van-radio v-for="item in cityList" :key="item.id" :name="item" class="userCheckbox">{{item.label}}</van-radio>
                            </van-radio-group>
                            <van-button style="width:100%;position: -webkit-sticky;position: sticky;bottom: 0;" @click="cityToChange()">确定</van-button>
                        </div>
                    </van-popup>
                    <!-- 单程往返 -->
                    <van-field label="单程往返" readonly>
                        <template #input>
                            <van-radio-group v-model="editForm.goBack" direction="horizontal" @change="timeTypeChange">
                                <van-radio name="0">单程</van-radio>
                                <van-radio name="1">往返</van-radio>
                            </van-radio-group>
                        </template>
                    </van-field>
                    <!-- 选择日期 -->
                    <van-field label="选择日期" v-model="formshowText.dateTitle" @click="dateShow()" readonly clickable required :rules="[{ required: true, message: '请选择日期'}]">
                        <template #input><span>{{formshowText.dateTitle}}</span></template>
                    </van-field>
                    <!-- 按天 -->
                    <van-calendar v-model="dateShow_day" type="range" :allow-same-day="true" :show-confirm="false" @confirm="date_dayChange" :min-date="minDate" :max-date="maxDate" />
                    <!-- 出差天数 -->
                    <van-field label="出差天数(天)" @blur="dayBlur()" v-model="editForm.dayCount" type="number" :rules="[{ required: true, message: '请输入请假天数' }]"></van-field>
            
                    <!-- 备注 -->
                    <van-field v-model="editForm.remark" label="备注" type="textarea" :autosize="{minHeight:100}"></van-field>
            </van-form>
            <!-- 提交 -->
            <div class="form_btn" style="position:fixed; bottom:0px;width:100%;">
                <div style="padding-bottom:10px;" v-if="editForm.id">
                    <van-button square block type="default" @click="clearEditForm()" style="width:100%;float:left;">清除</van-button>
                </div>
                <div style="padding-bottom:10px;">
                    <van-button square block type="info" @click="submitLeave()" :loading="submitLoading" style="width:100%;float:left;">提交</van-button>
                </div>
            </div>
        </div>
        <div class="list" v-if="active == 1">

            <van-collapse v-model="activeName" accordion class="list_collapse">
                <van-collapse-item v-for="item in leaveList" :key="item.id" title="标题1" :name="item.id">
                    <template #title>
                        <div class="collapse_label_l">人员：
                            <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName' :openid='item.ownerName'></TranslationOpenDataText></span>
                            <span v-else>{{item.ownerName}}</span>
                        </div>
                        <div class="collapse_label_r">天数：{{item.dayCount}}</div>
                        <div class="collapse_label_l">行程：{{item.cityFrom + '-' + item.cityTo}}</div>
                        <div class="collapse_label_r">状态：<span :class="statusClass[item.status]">{{statusList[item.status]}}</span></div>
                    </template>
                    <div class="wrapper">
                        <div><span>交通工具：</span><span>{{typeList[item.way]}}</span></div>
                        <div><span>申请时间：</span><span>{{item.indate}}</span></div>
                        <div><span>出差开始时间：</span><span>{{item.startDate}}</span></div>
                        <div><span>出差结束时间：</span><span>{{item.endDate}}</span></div>
                        <div><span>备注：</span><span>{{item.remark}}</span></div>
                    </div>
                    <div class="operation" v-if="(item.status != 0 && item.ownerId == user.id) || leaveAll">
                        <van-button v-if="item.status == 3 || item.status == 2" size="small" type="info" @click="submitAgain(item)">重新提交</van-button>
                        <van-button v-if="item.status == 3 || item.status == 2" style="margin-left:10px" size="small" type="danger" @click="deleteLeave(item.id)">删除</van-button>
                        <van-button v-if="item.status == 1" style="margin-left:10px" size="small" type="default" @click="cancelLeave(item.id)">撤回</van-button>
                    </div>
                </van-collapse-item>
            </van-collapse>
        </div>
        <div class="audit" v-if="active == 2">
                <van-collapse v-model="auditName" accordion class="list_collapse">
                    <van-collapse-item v-for="item in auditList" :key="item.id" title="标题2" :name="item.id">
                        <template #title>
                            <div class="collapse_label_l">人员：
                                <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName' :openid='item.ownerName'></TranslationOpenDataText></span>
                                <span v-else>{{item.ownerName}}</span>
                            </div>
                            <div class="collapse_label_r">天数：{{item.dayCount}}</div>
                            <div class="collapse_label_l">行程：{{item.cityFrom + '-' + item.cityTo}}</div>
                            <div class="collapse_label_r">状态：<span :class="statusClass[item.status]">{{statusList[item.status]}}</span></div>
                            <div class="operation">
                                <van-button size="small" type="info" :loading="item.approveLoading" @click.stop="approveLeave(item)">通过</van-button>
                                <van-button style="margin-left:15px" size="small" type="danger" @click.stop="denyLeave(item.id)">驳回</van-button>
                            </div>
                        </template>
                        <div class="wrapper">
                            <div><span>交通工具：</span><span>{{typeList[item.way]}}</span></div>
                            <div><span>申请时间：</span><span>{{item.indate}}</span></div>
                            <div><span>出差开始时间：</span><span>{{item.startDate}}</span></div>
                            <div><span>出差结束时间：</span><span>{{item.endDate}}</span></div>
                            <div><span>备注：</span><span>{{item.remark}}</span></div>
                        </div>
                    </van-collapse-item>
                </van-collapse>
                <van-popup v-model="denyReasonDialog" position="bottom" closeable >
                    <van-cell>请输入原因</van-cell>
                    <van-field class="form_input"
                        v-model="denyParm.denyReason" name="reason" type="textarea" placeholder="请输入您决定驳回的原因"
                        rows="3" autosize  />
                    <van-button style="width:100%;" type="info" :loading="denyLoading" @click="deny()">提交</van-button>
                </van-popup>
        </div>
    </div>
    <!-- 钉钉同步 -->
    <div class="content" v-show="isSyncData">
        <div class="list">
            <van-collapse v-model="activeName" accordion class="list_collapse">
                <van-collapse-item v-for="item in leaveList" :key="item.id" title="标题1" :name="item.id">
                    <template #title>
                        <div class="collapse_label_l">人员：
                            <span v-if="user.userNameNeedTranslate == 1"><TranslationOpenDataText type='userName' :openid='item.ownerName'></TranslationOpenDataText></span>
                            <span v-else>{{item.ownerName}}</span>
                        </div>
                        <div class="collapse_label_r">天数：{{item.dayCount}}天</div>
                        <div class="collapse_label_l">交通工具：{{typeList[item.way]}}</div>
                        <div class="collapse_label_r">状态：<span :class="statusClass[item.status]">{{statusList[item.status]}}</span></div>
                    </template>
                    <div class="wrapper">
                        <div><span>出差详情：</span>
                            <span v-for="items in item.projectList" :key="items.projectId">
                                <span>{{items.startDate + '至' + items.endDate}}</span><span>{{items.projectName}}</span><span>{{items.degreeName}}</span>
                            </span>
                        </div>
                        <div><span>出差开始时间：</span><span>{{item.startDate}}</span></div>
                        <div><span>出差结束时间：</span><span>{{item.endDate}}</span></div>
                        <div><span>备注：</span><span>{{item.remark}}</span></div>
                    </div>
                    <!-- <div class="operation">
                        <van-button size="small" type="info" @click="submitAgain(item)">关联</van-button>
                    </div> -->
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
</div>
</template>

<script>
// 映入城市
import citys from '../../assets/shju.json'

export default {
    data() {
        return {
            isSyncData: 0,
            wuduData: JSON.parse(localStorage.userInfo).timeType,
            user: JSON.parse(localStorage.userInfo),
            awayOfficeFil: false,
            awayOfficeAudit: false,
            awayOfficeList: false,

            canExamine: false,
            leaveAll: false,
            leaveAudit: false,
            leaveFil: false,
            active: 0,
            txselnum: 0,
            cityType: '',
            cityList: [],
            cityList2: [],
            editForm: {
                reason: '',
                startDate: '',
                endDate: '',
                way: 0,
                cityFrom: '',
                cityTo: '',
                goBack: '0',
                dayCount: 0,
                remark: '',
                projectId: '',
                wuduId: '',
            },
            formshowText: {
                name: '',
                dateTitle: ''
            },
            userRadio: null,
            userList: [],
            typeList: ['飞机','火车','汽车','轮船','其他'],
            currentDate: new Date(),
            minDate: new Date(2020,0,1),
            maxDate: new Date(2030,11,31),

            ownerIdShow: false,
            canExamine: false,
            dateShow_day: false,
            dateShow_hour: false,
            typeShow: false,
            submitLoading: false,



            activeName: '',
            leaveList: [],
            statusList: ['审核通过','待审核','已驳回','已撤销'],
            statusClass: ['','waiting','rejected',''],

            auditName: '',
            auditList: [],
            denyReasonDialog: false,
            denyLoading: false,
            denyParm: {
                id: '',
                denyReason: ''
            }

            
        }
    },
    filters: {
        departureCity(str, json) {
            for(var i in json) {
                if(json[i].value == str) {
                    return json[i].label
                }
            }
        }
    },
    mounted() {
        this.cityList = citys
        this.cityList2 = citys
        this.isSyncData = (this.wuduData.syncDingding || this.wuduData.syncFanwei)
        console.log(this.isSyncData, '是否是钉钉')

        for(let i in this.user.functionList){
            if(this.user.functionList[i].name == '查看全部出差'){
                this.leaveAll = true
            }
            if(this.user.functionList[i].name == '出差填报'){
                this.awayOfficeFil = true
            }
            if(this.user.functionList[i].name == '出差审核'){
                this.awayOfficeAudit = true
            }
        }
        if(this.isSyncData) {
            this.getLeaveList()
        } else {
            this.awayOfficeList = true
            if(this.awayOfficeAudit) {
                this.active = '2'
                this.getAuditList()
            }
            if(this.awayOfficeFil) {
                this.active = '0'
            }
        }
        
        this.getTxsel()
    },
    methods: {
        back(){
            history.back();
        },
        getDaysBetween(date1,date2){
            let  startDate = Date.parse(date1);
            let  endDate = Date.parse(date2);
            if (startDate>endDate){
                return 0;
            }
            if (startDate==endDate){
                return 1;
            }
            let days=(endDate - startDate)/(1*24*60*60*1000);
            return  days + 1;
        },
        formatDate(date) {
            let mon = date.getMonth() + 1
            return `${date.getFullYear()}-${mon<10?'0'+mon:mon}-${date.getDate()<10?'0'+date.getDate():date.getDate()}`;
        },
        activeChange(){
            sessionStorage.setItem('page',JSON.stringify(this.active))
            if(this.active == 1){
                this.getLeaveList()
            }
            if(this.active == 2){
                this.getAuditList()
            }
        },
// #region 
        cityToChange(){
            if(this.cityType == 1) {
                this.editForm.cityFrom = this.userRadio ? this.userRadio.label : ''
            } 
            if(this.cityType == 2) {
                this.editForm.cityTo = this.userRadio ? this.userRadio.label : ''
            }
            this.ownerIdShow = false
        },
        timeTypeChange(){
            if(this.editForm.startDate && this.editForm.endDate){
                this.formshowText.dateTitle = this.editForm.startDate+'\u3000至\u3000'+this.editForm.endDate
            }else{
                this.formshowText.dateTitle = ''
            }
            
        },
        dateShow(){
            this.dateShow_day = true
        },
        date_dayChange(value,date){
            this.dateShow_day = false
            this.editForm.startDate = this.formatDate(value[0])
            this.editForm.endDate = this.formatDate(value[1])
            this.formshowText.dateTitle = this.formatDate(value[0])+'\u3000至\u3000'+this.formatDate(value[1])
            this.editForm.dayCount = this.getDaysBetween(value[0],value[1])
        },
        date_hourChange(value){
            this.editForm.startDate = this.formatDate(value)
            this.formshowText.dateTitle = this.editForm.startDate
            this.dateShow_hour = false
        },
        dayBlur(){
            if(this.editForm.startDate && this.editForm.endDate){
                if(this.editForm.dayCount*1 < this.getDaysBetween(this.editForm.startDate,this.editForm.endDate) - 1){
                    this.editForm.dayCount = this.getDaysBetween(this.editForm.startDate,this.editForm.endDate) - 1
                }else if(this.editForm.dayCount*1 > this.getDaysBetween(this.editForm.startDate,this.editForm.endDate)){
                    this.editForm.dayCount = this.getDaysBetween(this.editForm.startDate,this.editForm.endDate)
                }
            }
        },
        hourBlur(){
            if(this.editForm.timeHours*1 > this.user.timeType.allday){
                this.editForm.timeHours = this.user.timeType.allday
            }
            if(this.editForm.timeHours*1 < 0){
                this.editForm.timeHours = 0.5
            }
        },

        typeChange(value,key){
            this.editForm.way = key
            this.typeShow = false
        },
        submitLeave(){
            this.$refs.loginForm.validate().then(()=>{
                console.log('success');
                this.submitLoading = true
                console.log(this.editForm, '看看数据')
                // return
                this.$axios.post("/business-trip/add", this.editForm)
                .then(res => {
                    this.submitLoading = false
                    if(res.code == "ok") {
                        this.editForm = {
                            reason: '',
                            startDate: '',
                            endDate: '',
                            way: 0,
                            cityFrom: '',
                            cityTo: '',
                            goBack: '0',
                            dayCount: 0,
                            remark: '',
                            projectId: '',
                            wuduId: '',
                        }
                        this.formshowText.dateTitle = ''
                        this.$toast.success('提交成功');
                    } else {
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.submitLoading = false;this.$toast.clear();console.log(err)});
            }).catch(()=>{})
        },
        clearEditForm(){
            this.editForm = {
                reason: '',
                startDate: '',
                endDate: '',
                way: 0,
                cityFrom: '',
                cityTo: '',
                goBack: '0',
                dayCount: 0,
                remark: '',
                projectId: '',
                wuduId: '',
            }
            this.formshowText.dateTitle = ''
        },
// #endregion

// #region
        cancelLeave(pid){
            this.$dialog.confirm({
                message: '确认撤回？',
            })
            .then(() => {
                // on confirm
                this.$axios.post("/business-trip/cancel", {id: pid})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.success('撤回成功')
                        this.getLeaveList()
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();console.log(err)});
            })
            .catch(() => {
                // on cancel
            });
        },
        deleteLeave(pid){
            this.$dialog.confirm({
                message: '确认删除？',
            })
            .then(() => {
                // on confirm
                this.$axios.post("/business-trip/delete", {id: pid})
                .then(res => {
                    if(res.code == "ok") {
                        this.$toast.success('删除成功')
                        this.getLeaveList()
                    } else {
                        this.$toast.fail('获取失败');
                    }
                }).catch(err=> {this.$toast.clear();console.log(err)});
            })
            .catch(() => {
                // on cancel
            });
            
        },
        submitAgain(item){
            item.goBack = item.goBack + ''
            this.editForm = {
                id: item.id,
                reason: item.reason,
                startDate: item.startDate,
                endDate: item.endDate,
                way: item.way,
                cityFrom: item.cityFrom,
                cityTo: item.cityTo,
                goBack: item.goBack,
                dayCount: item.dayCount,
                remark: item.remark,
                projectId: item.projectId,
                wuduId: item.wuduId,
            }
            this.timeTypeChange()
            console.log(item, '数据')
            this.active = 0
        },
// #endregion

        approveLeave(item){
            item.approveLoading = true
            this.$axios.post("/business-trip/approve", {id: item.id})
            .then(res => {
                if(res.code == "ok") {
                    this.$toast.success('已通过')
                    item.approveLoading = false
                    this.getAuditList()
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
            
        },
        denyLeave(pid){
            this.denyParm.id = pid
            this.denyReasonDialog = true
        },
        deny(){
            this.denyLoading = true
            this.$axios.post("/business-trip/deny", this.denyParm)
            .then(res => {
                if(res.code == "ok") {
                    this.$toast.success('已驳回')
                    this.denyReasonDialog = false
                    this.denyLoading = false
                    this.getAuditList()
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },

        getUserList(){
            this.$axios.post("/user/getSimpleActiveUserList", {})
            .then(res => {
                if(res.code == "ok") {
                    this.userList = res.data
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getTxsel(){
            this.$axios.post("/leave-sheet/getOTAvaiDays", {userId: this.user.id})
            .then(res => {
                if(res.code == "ok") {
                    if(res.data < 0.5){
                        this.txselnum = 0
                    }else{
                        this.txselnum = res.data.toFixed(1)
                    }
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getLeaveList(){
            let parameter = {
                pageIndex: 1,
                pageSize: 999,
                // status: '',
                // startDate: '',
                // endDate: '',
                // ownerId: '',
                // leaveType: ''
            }
            this.$axios.post("/business-trip/list", parameter)
            .then(res => {
                if(res.code == "ok") {
                    this.leaveList = res.data.records
                } else {
                    this.$toast.fail('获取失败:'+res.smg);
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        },
        getAuditList(){
            this.$axios.post("/business-trip/auditList", {
                pageSize: 999,
                pageIndex: 1
            }).then(res => {
                if(res.code == "ok") {
                    this.auditList = res.data.records
                    for(let i in this.auditList){
                        this.$set(this.auditList[i],'approveLoading',false)
                    }
                } else {
                    this.$toast.fail('获取失败');
                }
            }).catch(err=> {this.$toast.clear();console.log(err)});
        }
    },
}
</script>

<style lang="less" scoped>
.content{
    margin-top: 46px;
    overflow: auto;
    .edit{
        .userCheckbox {
            padding: 10px;
        }
        padding-bottom: 46px;
        .form_btn{
            z-index: 1000;
        }
    }
    .list{
        .list_collapse>div{
            margin: 4px;
        }
        .list_collapse{
            .collapse_label_l{
                width: 50%;
                padding: 4px;
                display: inline-block;
            }
            .collapse_label_r{
                width: 50%;
                padding: 4px;
                display: inline-block;
                .waiting {
                    color:orange;
                }
                .rejected {
                    color:red;
                }
            }
            .wrapper{
                div{
                    margin: 8px 16px;
                    zoom: 1; 
                }
                div:after{
                    content: ""; 
                    display: block; 
                    height: 0; 
                    clear: both; 
                    visibility: hidden; 
                }
                div span:nth-child(1){
                    width: 40%;
                    float: left;
                }
                div span:nth-child(2){
                    width: 60%;
                    float: left;
                }
            }
            .operation{
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
        
    }
    .audit{
        .list_collapse>div{
            margin: 4px;
        }
        .list_collapse{
            
            .collapse_label_l{
                width: 50%;
                padding: 4px;
                display: inline-block;
            }
            .collapse_label_r{
                width: 50%;
                padding: 4px;
                display: inline-block;
                .waiting {
                    color:orange;
                }
                .rejected {
                    color:red;
                }
            }
            .operation{
                margin-top: 5px;
                padding-top: 5px;
                border-top: 0.5px solid #ebedf0;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                button{
                    width: 1.2rem;
                }
            }
            .wrapper{
                div{
                    margin: 8px 16px;
                    zoom: 1; 
                }
                div:after{
                    content: ""; 
                    display: block; 
                    height: 0; 
                    clear: both; 
                    visibility: hidden; 
                }
                div span:nth-child(1){
                    width: 40%;
                    float: left;
                }
                div span:nth-child(2){
                    width: 60%;
                    float: left;
                }
            }
            .lookup{
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
            
        }
        
    }
}
</style>
<style>
.edit_form .invoice .van-field__label{
    color: #999;
}
.edit_form .invoice .van-field__control{
    color: #999;
}
</style>